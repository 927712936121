import React, { useState, useEffect, useContext } from "react";
import "./FlightFilters.scss";
import { Card, Checkbox, Collapse, Slider } from "antd";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../common/providers/AuthProvider";
import moment from "moment";
import TimeRangeSlider from "react-time-range-slider";
import { GlobalStatesContext } from "../../common/providers";
import refund from "../../assets/images/Icons/refund.png";
import norefund from "../../assets/images/Icons/no-refund.png";
import morning from "../../assets/images/Icons/morning-icon.png";
import afternoon from "../../assets/images/Icons/afternoon-icon.png";
import evening from "../../assets/images/Icons/evening-icon.png";
import night from "../../assets/images/Icons/night-icon.png";
import CustomFliFilterImg from "../../common/CustomImg/CustomFliFilterImg";
import { CloudFilled } from "@ant-design/icons";
const timeRange = { start: "00:00", end: "23:59" };

const FlightFilters = ({
  flightAirSearchResp,
  selectedTripType,
  updateFlightAirSearchRespObj,
  ResetAirlineMatrix,
  MultiCityIndex
}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    isLogin: { agent },
  } = useAuthContext();

  const { updateFlightFilterForSlider } = useContext(GlobalStatesContext);
  let flightDetails = (selectedTripType === 1 && flightAirSearchResp.airTravelType.toUpperCase() != "MULTIDESTINATION") ||
    (flightAirSearchResp.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
    ? flightAirSearchResp.flightDetails
    : flightAirSearchResp.airTravelType.toUpperCase() == "MULTIDESTINATION" ? flightAirSearchResp.multicityFlightDetails[MultiCityIndex] : flightAirSearchResp.ibFlightDetails;
 
  const { Panel } = Collapse;
  const [filters, setFilters] = useState({});
  const [departureVal, setDepartureVal] = useState(timeRange);
  const [arrivalVal, setArrivalVal] = useState(timeRange);
  const [priceRange, setPriceRange] = useState([]);
  let count = flightDetails?.length;

  const [resultCount, setResultCount] = useState(0);
  const flightTimings = [
    {
      id: "morning",
      start: "00:00",
      end: "06:00",
      label: "Morning",
      isChecked: false,
      icon: morning,
    },
    {
      id: "afterNoon",
      start: "06:01",
      end: "12:00",
      label: "AfterNoon",
      isChecked: false,
      icon: afternoon,
    },
    {
      id: "evening",
      start: "12:01",
      end: "18:00",
      label: "Evening",
      isChecked: false,
      icon: evening,
    },
    {
      id: "night",
      start: "18:01",
      end: "23:59",
      label: "Night",
      isChecked: false,
      icon: night,
    }
  ]
  let visibleCount = 0;
  let filtersObj = {
    stops: [],
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    depatureTimings: flightTimings,
    arrivaltimings: flightTimings,
    departure: {},
    arrival: {},
    airlines: [],
    fareTypes: [],
    connect: [],
  };
  useEffect(() => {
    let resCount = flightDetails?.filter((item) => item.isVisible).length;
    setResultCount(resCount);
  }, [flightDetails]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event?.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType]?.map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });

    // updateFlightFilterForSlider({
    //   ...filters,
    //   [filterType]: filters[filterType].map((filt) =>
    //     filt.id == filterId ? { ...filt, isChecked: checked } : filt
    //   ),
    // });


    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("result_div-container")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const getFlightMinPrice = (airline) => {
    // console.log(airline, "air-way");
    const minPriceFlightObj = flightDetails
      .filter((air) => air.airLine === airline)
      .reduce((prev, cur, i) => {
        let prevFare = prev?.fareFamilies?.fareFamilies[0]?.totalPublishFare;
        let curFare = cur?.fareFamilies?.fareFamilies[0]?.totalPublishFare;

        return prevFare < curFare ? prev : cur;
      });

    return isNaN(
      minPriceFlightObj?.fareFamilies?.fareFamilies?.[0]?.totalPublishFare
    )
      ? null
      : Math.floor(
        minPriceFlightObj.fareFamilies?.fareFamilies[0]?.totalPublishFare
      );
  };
  const getCombFlightMinPrice = (airline) => {
    // console.log(airline, "air-way");
    const minPriceFlightObj = flightDetails
      .filter((air) => air?.airLine === airline)
      .reduce((prev, cur, i) => {
        let prevFare = prev?.fareFamilies?.fareFamilies[0]?.totalPublishFare;
        let curFare = cur?.fareFamilies?.fareFamilies[0]?.totalPublishFare;
        // {console.log(prev, cur,prevFare,curFare, "famil")}

        return prevFare < curFare ? prev : cur;
      });

    return isNaN(
      minPriceFlightObj.fareFamilies?.totalPublishFare
    )
      ? null
      : Math.floor(
        minPriceFlightObj.fareFamilies?.fareFamilies?.totalPublishFare
      );

  };




  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    setDepartureVal(timeRange);
    setArrivalVal(timeRange);
// console.log(flightDetails,"length");
    if (
      (flightAirSearchResp.airTravelType.toUpperCase() === "ROUNDTRIP" &&
        flightAirSearchResp.resultsType.toUpperCase() === "COMBINED") || (flightAirSearchResp.airTravelType?.toUpperCase() === "MULTIDESTINATION" &&
          flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
    ) {
      for (let i = 0; i < flightDetails?.length; i++) {
        //Setting Stops Filters
        // const flightSegLength =
        //   flightDetails[i]?.flightDetails?.[0]?.flightSegments?.length;
        // checkIfExist(filtersObj.stops, flightSegLength) &&
        //   filtersObj.stops.push({
        //     id: flightSegLength,
        //     label:
        //       flightSegLength - 1 === 0
        //         ? "Direct"
        //         : `${flightSegLength - 1} Stop(s)`,
        //     isChecked: false,
        //   });
        flightDetails[i]?.flightDetails?.forEach(detail => {
          const flightSegLength = detail?.flightSegments?.length;
        
          if (checkIfExist(filtersObj.stops, flightSegLength)) {
            filtersObj.stops.push({
              id: flightSegLength,
              label: flightSegLength - 1 === 0 ? "Direct" : `${flightSegLength - 1} Stop(s)`,
              isChecked: false,
            });
          }
      
          //Setting Connecting Location Filters
          if (flightSegLength > 1) {
            const { flightSegments } = detail;
            const connFlightsSegments = flightSegments.filter(
              (_, index) => index !== flightSegLength - 1
            );
            connFlightsSegments.map(({ destination, destinationCity }) => {
              if (checkIfExist(filtersObj.connect, destination)) {
                filtersObj.connect.push({
                  id: destination,
                  label: destination,
                  labelHeading: destinationCity,
                  isChecked: false,
                });
              }
            });
          }
        });

        let flightTotPrice = Math.floor(
          flightDetails[i].fareFamilies.fareFamilies[0].totalPublishFare
        );
        if (i === 0) {
          filtersObj.price.minPrice = flightTotPrice;
        }
        let minFare = flightTotPrice;
        if (flightTotPrice > filtersObj.price.maxPrice) {
          filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
            flightTotPrice;
        }
        if (minFare < filtersObj.price.minPrice) {
          filtersObj.price.minPrice = minFare;
        }
        //Setting Departure Filter
        filtersObj.departure = timeRange;
        filtersObj.price.minPriceRange = filtersObj.price.minPrice;
        //Setting Arrival Filter
        filtersObj.arrival = timeRange;

        //Setting Airlines Filters
        checkIfExist(
          filtersObj.airlines,
          flightDetails[i]?.flightDetails?.[0]?.airLine
        ) &&
          filtersObj.airlines.push({
            id: flightDetails[i]?.flightDetails?.[0]?.airLine,
            label: flightDetails[i]?.flightDetails?.[0]?.airLineName,
            logo: flightDetails[i]?.flightDetail?.s[0]?.airLineLogo,
            count: flightDetails.filter(
              (air) => air?.airLine === flightDetails[i]?.airLine
            ).length,
            price: getCombFlightMinPrice(flightDetails?.[0]?.airLine),
            isChecked: false,
          });

        //Setting Fare Type Filters
        filtersObj.fareType = [
          { id: "refundable", label: "Refundable", isChecked: false },
          { id: "nonRefundable", label: "Non Refundable", isChecked: false },
        ];

        //Setting Fare Types Filters
        let { coupanType } = flightDetails[i].fareFamilies.fareFamilies[0];
        checkIfExist(filtersObj.fareTypes, coupanType) &&
          filtersObj.fareTypes.push({
            id: coupanType,
            label: coupanType,
            isChecked: false,
          });

        //Setting Connecting Location Filters
        // if (flightSegLength > 1) {
        //   const { flightSegments } = flightDetails[i].flightDetails[0];
        //   const connFlightsSegments = flightSegments.filter(
        //     (_, index) => index !== flightSegLength - 1
        //   );
        //   connFlightsSegments.map(({ destination, destinationCity }) => {
        //     checkIfExist(filtersObj.connect, destination) &&
        //       filtersObj.connect.push({
        //         id: destination,
        //         label: destination,
        //         labelHeading: destinationCity,
        //         isChecked: false,
        //       });
        //   });
        // }
        // if (flightSegLength > 1) {
        //   flightDetails[i].flightDetails.forEach(flightDetail => {
        //     const { flightSegments } = flightDetail;
        //     const connFlightsSegments = flightSegments.filter(
        //       (_, index) => index !== flightSegLength - 1
        //     );
        //     connFlightsSegments.map(({ destination, destinationCity }) => {
        //       if (checkIfExist(filtersObj.connect, destination)) {
        //         filtersObj.connect.push({
        //           id: destination,
        //           label: destination,
        //           labelHeading: destinationCity,
        //           isChecked: false,
        //         });
        //       }
        //     });
        //   });
        // }
      }
    } else {
     
      for (let i = 0; i < flightDetails?.length; i++) {
        //Setting Stops Filters
        const flightSegLength = flightDetails[i]?.flightSegments?.length;
        checkIfExist(filtersObj.stops, flightSegLength) &&
          filtersObj.stops.push({
            id: flightSegLength,
            label:
              flightSegLength - 1 === 0
                ? "Direct"
                : `${flightSegLength - 1} Stop(s)`,
            isChecked: false,
          });

        //Setting Price Range
        let flightTotPrice = Math.floor(
          flightDetails?.[i]?.fareFamilies?.fareFamilies?.[0]?.totalPublishFare
        );
        if (i === 0) {
          filtersObj.price.minPrice = flightTotPrice;
        }
        let minFare = flightTotPrice;
        if (flightTotPrice > filtersObj.price.maxPrice) {
          filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
            flightTotPrice;
        }
        if (minFare < filtersObj.price.minPrice) {
          filtersObj.price.minPrice = minFare;
        }
        //Setting Departure Filter
        filtersObj.departure = timeRange;
        filtersObj.price.minPriceRange = filtersObj.price.minPrice;
        //Setting Arrival Filter
        filtersObj.arrival = timeRange;

        //Setting Airlines Filters
        checkIfExist(filtersObj.airlines, flightDetails[i].airLine) &&
          filtersObj?.airlines?.push({
            id: flightDetails?.[i]?.airLine,
            label: flightDetails?.[i]?.airLineName,
            logo: flightDetails?.[i]?.airLineLogo,
            count: flightDetails.filter(
              (air) => air.airLine === flightDetails?.[i]?.airLine
            ).length,
            price: getFlightMinPrice(flightDetails?.[i]?.airLine),
            isChecked: false,
          });

        //Setting Fare Type Filters
        filtersObj.fareType = [
          { id: "refundable", label: "Refundable", isChecked: false },
          { id: "nonRefundable", label: "Non Refundable", isChecked: false },
        ];

        //Setting Fare Types Filters
      
        let { coupanType } = flightDetails?.[i]?.fareFamilies?.fareFamilies[0];
        checkIfExist(filtersObj.fareTypes, coupanType) &&
          filtersObj.fareTypes.push({
            id: coupanType,
            label: coupanType,
            isChecked: false,
          });

        //Setting Connecting Location Filters
        if (flightSegLength > 1) {
          const { flightSegments } = flightDetails[i];
          const connFlightsSegments = flightSegments.filter(
            (segment, index) => index !== flightSegLength - 1
          );
          // {console.log(moment(connFlightsSegments?.[0].arrivalDateTime).format("HH:mm"),"fli8info")}
          connFlightsSegments?.map(({ destination, destinationCity }) => {
            checkIfExist(filtersObj.connect, destination) &&
              filtersObj.connect.push({
                id: destination,
                label: destination,
                labelHeading: destinationCity,
                isChecked: false,
              });
          });
        }
      }
    }

    setFilters(filtersObj);
    // { console.log(filtersObj, "filter") }
    updateFlightFilterForSlider(filtersObj);

    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };

  const checkedFilters = (filterType) => {
    // console.log(filterType, "fill");
    return filters[filterType]?.filter((filter) => filter.isChecked);
  };
  ///oneway
  const mapFlightWithFilter = (flightDetails) => {
    // console.log(flightDetails, "hem");
    const stopsChecked = checkedFilters("stops");
    const airlinesChecked = checkedFilters("airlines");
    const fareTypeChecked = checkedFilters("fareType");
    const fareTypesChecked = checkedFilters("fareTypes");
    const connectChecked = checkedFilters("connect");
    const filterDepatureTimings = checkedFilters("depatureTimings");
    const filterArrivalTimings = checkedFilters("arrivaltimings");
    // console.log(filterArrivalTimings,filterDepatureTimings,"time");
    return flightDetails?.map((flight) => {

      let isVisible = true;

      if (
        stopsChecked?.length &&
        !stopsChecked
          .map((stop) => stop.id)
          .includes(flight?.flightSegments?.length)
      ) {
        isVisible = false;
      }

      const flightTotPrice = Math.floor(
        flight.fareFamilies?.fareFamilies[0]?.totalPublishFare
      );

      if (
        !(
          flightTotPrice >= filters?.price.minPrice &&
          flightTotPrice <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }

      const departureTime = moment(
        flight?.flightSegments?.[0]?.departureDateTime
      )?.format("HH:mm");
      if (
        !(
          departureTime >= filters?.departure?.start &&
          departureTime <= filters?.departure?.end
        )
      ) {
        isVisible = false;
      }

      const arrivalTime = moment(
        flight?.flightSegments[flight?.flightSegments?.length - 1]?.arrivalDateTime
      ).format("HH:mm");

      if (
        !(
          arrivalTime >= filters.arrival.start &&
          arrivalTime <= filters.arrival.end
        )
      ) {
        isVisible = false;
      }

      if (
        filterDepatureTimings?.length &&
        filterDepatureTimings?.filter((deptTime) => departureTime >= deptTime?.start &&
          departureTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }
      if (
        filterArrivalTimings?.length &&
        filterArrivalTimings?.filter((deptTime) => arrivalTime >= deptTime?.start &&
          arrivalTime <= deptTime.end)?.length == 0
      ) {
        isVisible = false;
      }

      if (
        airlinesChecked?.length &&
        !airlinesChecked?.map((airline) => airline.id)?.includes(flight.airLine)
      ) {
        isVisible = false;
      }

      const { isRefundable } = flight;

      if (
        fareTypeChecked?.length &&
        !fareTypeChecked
          .map((ftype) => ftype.id)
          .includes(isRefundable ? "refundable" : "nonRefundable")
      ) {
        isVisible = false;
      }

      if (fareTypesChecked.length) {
        const { coupanType } = flight.fareFamilies?.fareFamilies[0];
        if (!fareTypesChecked.map((ftypes) => ftypes.id)?.includes(coupanType)) {
          isVisible = false;
        }
      }

      if (connectChecked.length) {
        const { flightSegments } = flight,
          connFlightsSegments = flightSegments.filter(
            (_, index) => index !== flightSegments.length - 1
          );
        if (
          !connectChecked
            .map((conn) => conn.id)
            .some((connDest) =>
              connFlightsSegments
                .map(({ destination }) => destination)
                .includes(connDest)
            )
        ) {
          isVisible = false;
        }
      }

      isVisible && visibleCount++;

      setResultCount(visibleCount);

      return { ...flight, isVisible: isVisible };
    });
  };
  ///roundtrip @ combined
  const mapCombinedFlightWithFilter = (flightDetails) => {
    const stopsChecked = checkedFilters("stops");
    const airlinesChecked = checkedFilters("airlines");
    const fareTypeChecked = checkedFilters("fareType");
    const fareTypesChecked = checkedFilters("fareTypes");
    const connectChecked = checkedFilters("connect");
    const filterDepatureTimings = checkedFilters("depatureTimings");
    const filterArrivalTimings = checkedFilters("arrivaltimings");
    // console.log(flightDetails,"time-2");

    return flightDetails?.map((flight) => {
      let isVisible = true;
      // {console.log(flight,"fly-to")}
      // if (
      //   stopsChecked.length &&
      //   (!stopsChecked
      //     .map((stop) => stop.id)
      //     .includes(flight.flightDetails[0].flightSegments.length) ||
      //     !stopsChecked
      //       .map((stop) => stop.id)
      //       .includes(flight.flightDetails[1].flightSegments.length))
      // ) {
      //   isVisible = false;
      // }
      if (stopsChecked.length) {
        const onwardStopsCount = flight.flightDetails[0].flightSegments.length; // Number of stops is segments - 1
        const returnStopsCount = flight.flightDetails[1].flightSegments.length; // Number of stops is segments - 1

        // Check if either onward or return stops count matches the selected stops
        const onwardMatches = stopsChecked.some((stop) => stop.id === onwardStopsCount);
        const returnMatches = stopsChecked.some((stop) => stop.id === returnStopsCount);

        if (!onwardMatches && !returnMatches) {
          isVisible = false;
        }
      }

      const flightTotPrice = Math.floor(
        flight.fareFamilies.fareFamilies[0].totalPublishFare
      );
      if (
        !(
          flightTotPrice >= filters.price.minPrice &&
          flightTotPrice <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }




      const departureTime = moment(
        flight?.flightDetails?.[0]?.flightSegments?.[0]?.departureDateTime
      ).format("HH:mm");
      if (
        !(
          departureTime >= filters.departure.start &&
          departureTime <= filters.departure.end
        )
      ) {
        isVisible = false;
      }

      const arrivalTime = moment(
        flight?.flightDetails?.[0]?.flightSegments[
          flight?.flightDetails?.[0]?.flightSegments.length - 1
        ].arrivalDateTime
      ).format("HH:mm");
      if (
        !(
          arrivalTime >= filters.arrival.start &&
          arrivalTime <= filters.arrival.end
        )
      ) {
        isVisible = false;
      }
      if (
        filterDepatureTimings.length &&
        filterDepatureTimings.filter((deptTime) => departureTime >= deptTime.start &&
          departureTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }
      if (
        filterArrivalTimings.length &&
        filterArrivalTimings.filter((deptTime) => arrivalTime >= deptTime.start &&
          arrivalTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }
      //let  count = 0;
      // flight?.flightDetails?.map((flightItem) => {
      //   // {console.log(flightItem,flight,airlinesChecked,"venu")}
      //   if (
      //     airlinesChecked.length &&
      //     !airlinesChecked
      //       .map((airline) => airline.id)
      //       .includes(flightItem.airLine)
      //   ) {
      //     isVisible = false;
      //     //count++;
      //   }
      //   // if(flight?.flightDetails?.length != count){
      //   //   isVisible = true;
      //   // }
      // });
      if (airlinesChecked.length) {
        const airlinesInFlight = flight.flightDetails.flatMap((flightItem) => flightItem.airLine);
        if (!airlinesChecked.map((airline) => airline.id).some((airlineId) => airlinesInFlight.includes(airlineId))) {
          isVisible = false;
        }
      }

      const { isRefundable } = flight?.flightDetails?.[0];
      ;
      if (
        fareTypeChecked.length &&
        !fareTypeChecked
          .map((ftype) => ftype.id)
          .includes(isRefundable ? "refundable" : "nonRefundable")
      ) {
        isVisible = false;
      }

      if (fareTypesChecked.length) {
        const { coupanType } = flight.fareFamilies.fareFamilies[0];
        if (!fareTypesChecked.map((ftypes) => ftypes.id).includes(coupanType)) {
          isVisible = false;
        }
      }

      // if (connectChecked.length) {
      //   const { flightSegments } = flight.flightDetails[0],
      //     connFlightsSegments = flightSegments.filter(
      //       (segment, index) => index !== flightSegments.length - 1
      //     );
      //   if (
      //     !connectChecked
      //       .map((conn) => conn.id)
      //       .some((connDest) =>
      //         connFlightsSegments
      //           .map(({ destination }) => destination)
      //           .includes(connDest)
      //       )
      //   ) {
      //     isVisible = false;
      //   }
      // }
      if (connectChecked?.length) {
        
        const flightSegments = flight?.flightDetails?.map((seg) => seg.flightSegments).flat();
      
       
        const connFlightsSegments = flightSegments?.filter((_, index) => index !== flightSegments.length - 1);
      
        //console.log(flightSegments, "seg", flightSegments?.length - 1, connFlightsSegments, connectChecked);
      
        
        const connectionDestinations = connectChecked.map((conn) => conn.id);
        const flightDestinations = connFlightsSegments.map(({ destination }) => destination);
      
                if (!connectionDestinations.some((connDest) => flightDestinations.includes(connDest))) {
          isVisible = false;
        }
      }
      isVisible && visibleCount++;

      setResultCount(visibleCount);

      return { ...flight, isVisible: isVisible };
    });
  };
  // multicity
  const mapMultiCombinedFlightFilter = (flightDetails) => {
    const stopsChecked = checkedFilters("stops");
    const airlinesChecked = checkedFilters("airlines");
    const fareTypeChecked = checkedFilters("fareType");
    const fareTypesChecked = checkedFilters("fareTypes");
    const connectChecked = checkedFilters("connect");
    const filterDepatureTimings = checkedFilters("depatureTimings");
    const filterArrivalTimings = checkedFilters("arrivaltimings");
   // console.log(flightDetails, "time-2");

    return flightDetails?.map((flight) => {
      let isVisible = true;

      // if (stopsChecked.length) {
      //   const onwardStopsCount = flight.flightDetails[0].flightSegments.length; // Number of stops is segments - 1
      //   const returnStopsCount = flight.flightDetails[1].flightSegments.length; // Number of stops is segments - 1

      //   // Check if either onward or return stops count matches the selected stops
      //   const onwardMatches = stopsChecked.some((stop) => stop.id === onwardStopsCount);
      //   const returnMatches = stopsChecked.some((stop) => stop.id === returnStopsCount);

      //   if (!onwardMatches && !returnMatches) {
      //     isVisible = false;
      //   }
      // }
      if (stopsChecked.length) {
        const flightSegmentsCounts = flight.flightDetails.map(detail => detail.flightSegments.length);

        // Check if any of the flight segments count matches the selected stops
        const matches = stopsChecked.some((stop) =>
          flightSegmentsCounts.includes(stop.id)
        );

        if (!matches) {
          isVisible = false;
        }
      }
      const flightTotPrice = Math.floor(
        flight.fareFamilies.fareFamilies[0].totalPublishFare
      );
      if (
        !(
          flightTotPrice >= filters.price.minPrice &&
          flightTotPrice <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }




      const departureTime = moment(
        flight?.flightDetails?.[0]?.flightSegments?.[0]?.departureDateTime
      ).format("HH:mm");
      if (
        !(
          departureTime >= filters.departure.start &&
          departureTime <= filters.departure.end
        )
      ) {
        isVisible = false;
      }

      const arrivalTime = moment(
        flight?.flightDetails?.[0]?.flightSegments[
          flight?.flightDetails?.[0]?.flightSegments.length - 1
        ].arrivalDateTime
      ).format("HH:mm");
      if (
        !(
          arrivalTime >= filters.arrival.start &&
          arrivalTime <= filters.arrival.end
        )
      ) {
        isVisible = false;
      }
      if (
        filterDepatureTimings.length &&
        filterDepatureTimings.filter((deptTime) => departureTime >= deptTime.start &&
          departureTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }
      if (
        filterArrivalTimings.length &&
        filterArrivalTimings.filter((deptTime) => arrivalTime >= deptTime.start &&
          arrivalTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }

      if (airlinesChecked.length) {
        const airlinesInFlight = flight.flightDetails.flatMap((flightItem) => flightItem.airLine);
        // console.log(airlinesInFlight, "airlines in flight");
        // console.log(airlinesChecked.map((airline) => airline.id), "airlines checked");

        if (!airlinesChecked.map((airline) => airline.id).some((airlineId) => airlinesInFlight.includes(airlineId))) {
          isVisible = false;
          // break;
        }
      }

      const { isRefundable } = flight?.flightDetails?.[0];
      ;
      if (
        fareTypeChecked.length &&
        !fareTypeChecked
          .map((ftype) => ftype.id)
          .includes(isRefundable ? "refundable" : "nonRefundable")
      ) {
        // console.log(fareTypeChecked,isRefundable, "checked");
        isVisible = false;
      }

      if (fareTypesChecked.length) {
        const { coupanType } = flight?.fareFamilies?.fareFamilies[0];
        if (!fareTypesChecked?.map((ftypes) => ftypes.id).includes(coupanType)) {
          isVisible = false;
        }
      }

      // if (connectChecked?.length) {
      //   const flightSegments = flight?.flightDetails?.map((seg, i) => seg.flightSegments).flat(),
      //   connFlightsSegments = flightSegments?.filter(
      //     (segment, index) => index !== flightSegments?.length - 1
      //   );
      //   console.log(flightSegments,"seg", flightSegments?.length - 1, connFlightsSegments,connectChecked)
      
      //   if (
      //     !connectChecked
      //       .map((conn) => conn.id)
      //       .some((connDest) =>
      //         connFlightsSegments
      //           .map(({ destination }) => destination)
      //           ?.includes(connDest)
      //       )
      //   ) {
      //     isVisible = false;
      //   }
      // }
      if (connectChecked?.length) {
        
        const flightSegments = flight?.flightDetails?.map((seg) => seg.flightSegments).flat();
      
       
        const connFlightsSegments = flightSegments?.filter((_, index) => index !== flightSegments.length - 1);
      
        //console.log(flightSegments, "seg", flightSegments?.length - 1, connFlightsSegments, connectChecked);
      
        
        const connectionDestinations = connectChecked.map((conn) => conn.id);
        const flightDestinations = connFlightsSegments.map(({ destination }) => destination);
      
                if (!connectionDestinations.some((connDest) => flightDestinations.includes(connDest))) {
          isVisible = false;
        }
      }

      isVisible && visibleCount++;

      setResultCount(visibleCount);

      return { ...flight, isVisible: isVisible };
    });
  };

  const multiCombinedFilters = () => {
    // { console.log("Multicity5", MultiCityIndex,) }
    updateFlightAirSearchRespObj({
      ...flightAirSearchResp,
      flightDetails: mapMultiCombinedFlightFilter(flightDetails),
    });
  };
  const combinedFilters = () => {
    // { console.log("Multicity4", MultiCityIndex,) }
    updateFlightAirSearchRespObj({
      ...flightAirSearchResp,
      flightDetails: mapCombinedFlightWithFilter(flightDetails),
    });
  };
  //roundtrip separate
  const applyFilters = () => {
    if (selectedTripType === 1 && flightAirSearchResp.airTravelType.toUpperCase() != "MULTIDESTINATION" ||
      (flightAirSearchResp.airTravelType.toUpperCase() === "ROUNDTRIP" && flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: mapFlightWithFilter(flightDetails),
      });
    } else if (flightAirSearchResp.airTravelType.toUpperCase() == "MULTIDESTINATION") {
      let copyFlightsFilters = [...flightAirSearchResp.flightDetails];

      copyFlightsFilters[MultiCityIndex] = mapFlightWithFilter(flightDetails);
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        multicityFlightDetails: copyFlightsFilters,
      });

    }
    else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        ibFlightDetails: mapFlightWithFilter(flightDetails),
      });
    }
  };

  const appendZero = (hrMn) => {
    return ("0" + hrMn.hours)?.slice(-2) + ":" + ("0" + hrMn.minutes)?.slice(-2);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
    scrollToListTop();
  };

  const timeChangeCompleteHandler = (filterType, time) => {
    setFilters({
      ...filters,
      [filterType]: {
        start: appendZero(time.start),
        end: appendZero(time.end),
      },
    });
    scrollToListTop();
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  const depTimeHandler = (time) => {
    if (
      Number(time.start.split(":")[0]) >= 0 &&
      Number(time.end.split(":")[0]) >= 0
    ) {
      setDepartureVal(time);
    }
  };

  const arrTimeHandler = (time) => {
    if (
      Number(time.start.split(":")[0]) >= 0 &&
      Number(time.end.split(":")[0]) >= 0
    ) {
      setArrivalVal(time);
    }
  };

  useEffect(() => {
    setDynamicFilters();
  }, [selectedTripType, MultiCityIndex]);

  useEffect(() => {
    if (
      (flightAirSearchResp.airTravelType.toUpperCase() === "ROUNDTRIP" &&
        flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
      // (flightAirSearchResp.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
    ) {
      Object.keys(filters).length && combinedFilters();
    } else if (flightAirSearchResp.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightAirSearchResp.resultsType.toUpperCase() === "COMBINED") {
      Object.keys(filters).length && multiCombinedFilters();
    } else {
      Object.keys(filters).length && applyFilters();
    }
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else if (filterType === "departure" || filterType === "arrival") {
      initFilterType = timeRange;

      if (filterType == "departure") {
        setDepartureVal(timeRange);
      } else {
        setArrivalVal(timeRange);
      }
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );
  const sortedFilters = filters?.stops?.slice()?.sort((a, b) => {
    if (a?.label === "Direct") {
      return -1;
    }
    if (b?.label === "Direct") {
      return 1;
    }
    return a?.label?.localeCompare(b?.label);
  });
//{console.log(filters,"filterrr")}
  return (
    <Card>
      <div className="flight-filters slider-icon-1">
        <div className="flight-result-indicator">
          <p>
            {/* Showing {resultCount} Of {count} Flights */}
            We Found {resultCount} Flights For You
          </p>
        </div>
        <div className="overall-filter-container">
          <div className="overall-filter-header">
            <p className="filter-text">Filters</p>
            <p
              className="clear-text"
              onClick={() => {
                ResetAirlineMatrix(true);
                setDynamicFilters();
              }}
            >
              Clear all
            </p>
          </div>
          <div className="overall-filter-body">
            <div className="stops-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel header="Stops" key="1" extra={extraPanel("stops")}>
                  {sortedFilters?.map((stop, i) => (
                    <p key={i + stop.id}>
                      <Checkbox
                        checked={stop?.isChecked}
                        onChange={(e) => onChange(e, "stops", stop.id)}
                      >
                        {stop.label}
                      </Checkbox>
                    </p>
                  ))}
                </Panel>
              </Collapse>
            </div>
            <div className="slider-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel header="Price" key="1" extra={extraPanel("price")}>
                  <div className="slider-label">
                    <span>
                      {currencyValue(priceRange[0])}&nbsp;{activeCurrency}
                    </span>
                    <span>
                      {currencyValue(priceRange[1])}&nbsp;{activeCurrency}
                    </span>
                  </div>
                  {filters.price && (
                    <Slider
                      range
                      step={1}
                      defaultValue={[
                        filters.price.minPrice,
                        filters.price.maxPrice,
                      ]}
                      value={priceRange}
                      min={filters.price.minPriceRange}
                      max={filters.price.maxPriceRange}
                      onChange={priceChangeHandler}
                      onAfterChange={priceChangeCompleteHandler}
                    />
                  )}
                </Panel>
              </Collapse>
            </div>
            {/* <div className="airlines-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header="Departure Time"
                  key="1"
                  extra={extraPanel("departure")}
                >
                  <div className="slider-label">
                    <span>{departureVal.start}</span>
                    <span>{departureVal.end}</span>
                  </div>
                  <TimeRangeSlider
                    disabled={false}
                    format={24}
                    maxValue={timeRange.end}
                    minValue={timeRange.start}
                    name={"dep_time_range"}
                    onChangeComplete={(time) =>
                      timeChangeCompleteHandler("departure", time)
                    }
                    onChange={depTimeHandler}
                    step={15}
                    value={departureVal}
                  />
                </Panel>
              </Collapse>
            </div>
            <div className="airlines-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header="Arrival Time"
                  key="1"
                  extra={extraPanel("arrival")}
                >
                  <div className="slider-label">
                    <span>{arrivalVal.start}</span>
                    <span>{arrivalVal.end}</span>
                  </div>
                  <TimeRangeSlider
                    disabled={false}
                    format={24}
                    maxValue={timeRange.end}
                    minValue={timeRange.start}
                    name={"arr_time_range"}
                    onChangeComplete={(time) =>
                      timeChangeCompleteHandler("arrival", time)
                    }
                    onChange={arrTimeHandler}
                    step={15}
                    value={arrivalVal}
                  />
                </Panel>
              </Collapse>
            </div> */}


            <div className="airlines-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header="DEPARTURE TIME"
                  key="1"
                  extra={extraPanel("depatureTimings")}
                >
                  <div style={{ border: "2px solid transparent", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}>
                    {filters.depatureTimings &&
                      filters.depatureTimings.map((deptTime, i) => (
                        <p className="label-nav-set" key={i + deptTime.id}>
                          <Checkbox
                            className="check-box-css"
                            checked={deptTime.isChecked}
                            onChange={(e) => onChange(e, "depatureTimings", deptTime.id)}
                          >
                            <img src={deptTime.label == "Morning" ? morning : deptTime.label == "AfterNoon" ? afternoon : deptTime.label == "Evening" ? evening : night} ></img>
                            <p className="time-txt">{deptTime.start.split(":")[0]}-{deptTime.end.split(":")[0]}</p>
                          </Checkbox>
                        </p>
                      ))}
                  </div>

                </Panel>
              </Collapse>
            </div>
            <div className="airlines-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header="ARRIVAL TIME"
                  key="1"
                  extra={extraPanel("arrivaltimings")}
                >

                  <div style={{ border: "2px solid transparent", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}>
                    {filters.arrivaltimings &&
                      filters.arrivaltimings.map((deptTime, i) => (
                        <p className="label-nav-set" key={i + deptTime.id}>
                          <Checkbox
                            className="check-box-css"
                            checked={deptTime.isChecked}
                            onChange={(e) => onChange(e, "arrivaltimings", deptTime.id)}
                          >
                            {/* <img src={deptTime.icon} ></img> */}
                            <img src={deptTime.label == "Morning" ? morning : deptTime.label == "AfterNoon" ? afternoon : deptTime.label == "Evening" ? evening : night} ></img>
                            <p className="time-txt">{deptTime.start.split(":")[0]}-{deptTime.end.split(":")[0]}</p>
                          </Checkbox>
                        </p>
                      ))}
                  </div>

                </Panel>
              </Collapse>
            </div>






            <div className="airlines-filter">
              <Collapse
                className="customscroll"
                defaultActiveKey={["1"]}
                expandIconPosition={"right"}
              >
                <Panel
                  header={"Airlines"}
                  key="1"
                  extra={extraPanel("airlines")}
                >
                  {filters.airlines &&
                    filters.airlines.map((airline, i) => (
                      <p key={i + airline.id}>
                        {/* {console.log(airline, "line")} */}
                        <Checkbox
                          checked={airline.isChecked}
                          onChange={(e) => onChange(e, "airlines", airline.id)}
                        >
                          <span className="d-flex justify-content-between">
                            <span className="airline-wrapper">
                              <CustomFliFilterImg
                                className="airline-img"
                                src={airline.logo}
                                alt={airline.id}
                              />
                              {/* {airline.logo && (
                                <img
                                  src={airline.logo}
                                  alt=""
                                  style={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 4,
                                  }}
                                /> */}
                              {/* )}{" "} */}
                              {" "}{airline.label} {" "}
                              ({flightDetails.filter((air) => (air.airLine === airline.id || air?.flightDetails?.[0]?.airLine === airline.id)).length})
                              {/* ({airline.count}) */}
                            </span>
                            {/* {airline.price && (
                              <span className="airlinePrice">
                                {activeCurrency}{" "}
                                {Math.round(currencyValue(airline.price))}
                              </span>
                            )} */}
                          </span>
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>


            <div className="fare-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header={"Fare Type"}
                  key="1"
                  extra={extraPanel("fareType")}
                >
                  {filters.fareType &&
                    filters.fareType.map((ftype, i) => (
                      <p key={i + ftype.id}>
                        <Checkbox
                          checked={ftype.isChecked}
                          onChange={(e) => onChange(e, "fareType", ftype.id)}
                        >
                          <img style={{ width: "20px", marginRight: "2%" }} src={ftype.label == "Refundable" ? refund : norefund} ></img>
                          {ftype.label}
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
            {agent && (
              <div className="fare-types-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel
                    header={"Fare Class"}
                    key="1"
                    extra={extraPanel("fareTypes")}
                  >
                    {filters.fareTypes &&
                      filters.fareTypes.map((ftypes) => (
                        <p>
                          <Checkbox
                            checked={ftypes.isChecked}
                            onChange={(e) =>
                              onChange(e, "fareTypes", ftypes.id)
                            }
                          >
                            {ftypes.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
            )}
            <div className="connecting-locations">
              <Collapse
                className="customscroll"
                defaultActiveKey={["1"]}
                expandIconPosition={"right"}
              >
                <Panel
                  header={"Connecting Locations"}
                  key="1"
                  extra={extraPanel("connect")}
                >
                  {/* {console.log(filters,"fill")} */}
                  {filters.connect &&
                    filters.connect.map((conn, i) => (
                      <p key={conn.id + i}>
                        <Checkbox
                          checked={conn.isChecked}
                          onChange={(e) => onChange(e, "connect", conn.id)}
                        >
                          <span class="fa fa-map-marker" style={{ color: "#35459c" }}></span>  <b>{conn.label}</b>-<span>{conn.labelHeading}</span>

                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default FlightFilters;
